import React, { useRef, useState, useEffect } from "react"

import Layout from "../components/layout"

import NewsHubMenu from "../content/news-hub/news_hub_menu"
import NewsHubCarousel from "../content/news-hub/news_hub_carousel"

import { usePrismNewsHub } from "../hooks/use_prism-news-hub"

import "../styles/pages/news-hub/news_hub.scss"
import { NewsHubItem, NewsHubItemTypes } from "../components/_news_hub/_news_hub_item"
import { NewsHubSearch } from "../components/_news_hub/_news_hub_search"

const NewsHubPage = () => {
  const blogs = usePrismNewsHub()
  const blog_body = useRef(null)
  const [append, setAppend] = useState(false)
  const [newBlogs, setNewBlogs] = useState([])
  const single_layout_array = ["left", "middle", "right"]
  const [blog_current, set_blog_current] = useState(3)
  let blog_total = blogs.edges.length - 1

  const [has_more, setHasMore] = useState(blog_total > 7)

  useEffect(() => {
    /* eslint-disable */
    if (append) {
      let blog_array = []
      let to_add = 0
      let initial_count = blog_current

      if (blog_current + 3 < blog_total) {
        to_add = 3
      } else {
        to_add = blog_total - blog_current
        setHasMore(false)
      }

      for (let i = 0; i < to_add; i++) {
        blog_array.push({
          blog: blogs.edges[initial_count],
          type: NewsHubItemTypes.SINGLE,
          single_layout: single_layout_array[i],
        })
        initial_count++
      }

      setNewBlogs([...newBlogs, ...blog_array])
      set_blog_current(blog_current + to_add)
      setAppend(false)
    }
  }, [append])

  return (
    <Layout _class="news-hub">
      <NewsHubSearch></NewsHubSearch>
      <div className="news-hub-container">
        <div className="news-hub_header_stage">
          <div className="news-hub_carrousel">
            <NewsHubCarousel datablogs={blogs}></NewsHubCarousel>
          </div>
          <NewsHubMenu path="/calorex-news-hub/"></NewsHubMenu>
        </div>
        <div className="news-hub_body" ref={blog_body}>
          {blogs.edges.length >= 2 ? (
            <NewsHubItem
              blog={blogs.edges[1]}
              type={NewsHubItemTypes.MAIN}
            ></NewsHubItem>
          ) : null}
          {blogs.edges.length >= 3 ? (
            <NewsHubItem
              blog={blogs.edges[2]}
              type={NewsHubItemTypes.TO_LEFT}
            ></NewsHubItem>
          ) : null}
          {blogs.edges.length >= 4 ? (
            <NewsHubItem
              blog={blogs.edges[3]}
              type={NewsHubItemTypes.TO_RIGHT}
            ></NewsHubItem>
          ) : null}
          {blogs.edges.length >= 5 ? (
            <NewsHubItem
              blog={blogs.edges[4]}
              type={NewsHubItemTypes.TO_LEFT}
            ></NewsHubItem>
          ) : null}
          {blogs.edges.length >= 6 ? (
            <NewsHubItem
              blog={blogs.edges[5]}
              type={NewsHubItemTypes.SINGLE}
              single_layout="left"
            ></NewsHubItem>
          ) : null}
          {blogs.edges.length >= 7 ? (
            <NewsHubItem
              blog={blogs.edges[6]}
              type={NewsHubItemTypes.SINGLE}
              single_layout="middle"
            ></NewsHubItem>
          ) : null}
          {blogs.edges.length >= 8 ? (
            <NewsHubItem
              blog={blogs.edges[7]}
              type={NewsHubItemTypes.SINGLE}
              single_layout="right"
            ></NewsHubItem>
          ) : null}
          {newBlogs.map((item, key) => (
            <NewsHubItem
              key={`news-hub_item_${key}`}
              blog={item.blog}
              type={item.type}
              single_layout={item.single_layout}
            ></NewsHubItem>
          ))}
        </div>
        {has_more ? (
          <div className="news-hub_footer">
            <a className="news-hub_footer_button" onClick={() => setAppend(true)}>
              <p className="news-hub_footer_text">Cargar más noticias</p>
            </a>
          </div>
        ) : null}
      </div>
    </Layout>
  )
}

export default NewsHubPage
